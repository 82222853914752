import React from "react"
import Layout from "../components/layout"
import Seo from "../components/seo"

import { Player } from "@lottiefiles/react-lottie-player"
import aboutUs1 from "../images/about-us/about-us.jpg"
import aboutUs2 from "../images/about-us/about-us-2.jpg"

const pageTitle = "در باره ما"

export default function AboutUsPage() {
  return (
    <Layout>
      <Seo title={pageTitle} description="در باره شرکت مشارکت نیرو آریا کیش" />
      <div className="container px-0 sm:px-2">
        <section
          id="about-us-hero"
          className="grid grid-cols-2 px-5 sm:grid-cols-1 items-center sm:px-1  sm:py-10"
        >
          <div
            id="hero-animation"
            className="border rounded-md px-2 m-5 shadow-lg shadow-gray-300"
          >
            <Player
              autoplay
              loop
              src="https://assets10.lottiefiles.com/packages/lf20_tljjahng.json"
            ></Player>
          </div>
          <div className="flex flex-col items-center space-y-6">
            <h1 className="text-6xl sm:text-4xl font-semibold text-primary">
              {pageTitle}
            </h1>
            <h2 className="text-2xl sm:text-xl font-semibold text-primary">
              شرکت مشارکت نیرو آریا کیش
            </h2>
          </div>
        </section>
        <section
          id="about-us-1"
          className="grid grid-cols-2 sm:grid-cols-1 items-center  py-10 sm:px-1 bg-primary"
        >
          <div className="flex flex-col items-center space-y-6 p-10 sm:px-5 font-medium text-white">
            <h3 className="text-4xl ">معرفی شرکت</h3>
            <h4 className="text-2xl ">خلاصه ای از شرکت مشارکت نیرو</h4>
            <div className="text-lg font-normal text-white">
              <p>
                شرکت مشارکت نیرو آریا کیش با تکیه بر دانش و تجربه مدیران خود به
                جهت حضور مستقیم برای عرضه محصولات قابل ارائه خود در بازار ایران
                در سال 1395 تاسیس گردید.
              </p>
              <p>
                عمده فعالیت موسسان این مجموعه، فروش و تامین قطعات یدکی
                موتورژنراتورهای دیزلی و گاز سوز برای بازار ایران از خارج از کشور
                و همچنین تامین این دستگاه ها برای کشور های حوزه خلیج فارس،
                افغانستان، پاکستان و حتی کشورهای اروپایی بوده است.
              </p>
              <p>
                در سال های اخیر به جهت شرایط موجود در کشور “مشارکت نیرو” تصمیم
                به حضور مستقیم در بازار ایران را در برنامه خود قرارداد و با حضور
                در نمایشگاه های مختلف و ارتباط با مشتریان نهایی اقدام به عرضه
                محصولات خود از معتبرترین برندهای دنیا نموده است.
              </p>
              <p>
                در راستای تحقق سیاست های “مشارکت نیرو”، این مجموعه اقدام به
                سرمایه گذاری، تامین مولد، نصب و راه اندازی و بهره برداری و تامین
                قطعات یدکی در مناطق حساس و فاقد برق نموده که از عمده این موارد
                می توان به نیروگاه جزیره ابوموسی به ظرفیت 10MW، نیروگاه جزیره
                خارک به ظرفیت 3MW و نیروگاه بهاباد یزد به ظرفیت 1.4MW ، نیروگاه
                جزیره هرمز به ظرفیت 11.2MW (در دست احداث) اشاره نمود.
              </p>
              <p>
                سیاست های “مشارکت نیرو” صرفا در بخش بازرگانی و تامین قطعات خلاصه
                نشده و این مجموعه در راستای انتقال دانش فنی و تکنولوژی به داخل
                کشور و تولید تجهیزاتی با کیفیت بهتر و قیمت مناسب تر، نسبت به عقد
                قراردادهای تولید مشترک با چندین کمپانی معتبر اروپایی و آسیایی
                اقدام نموده است.
              </p>
            </div>
          </div>
          <div id="about-us-pic1" className="p-10 sm:px-5">
            <img src={aboutUs1} alt="در باره مشارکت نیرو" />
          </div>
        </section>
        <section
          id="about-us-1-extra"
          className="px-10 sm:px-5 text-lg font-normal text-white bg-primary"
        >
          <p>
            با توجه به دانش، تخصص و طی دوره های مختلف بازرگانی و فنی در زمینه
            انرژی های تجدیدپذیر توسط تیم فنی این مجموعه و همچنین همکاری با به
            نام ترین فعالان این صنعت در جهان”مشارکت نیرو” در این صنعت نیز فعالیت
            خود را آغاز نموده و آماده ارائه خدماتی نظیر طراحی، تهیه، نصب و راه
            اندازی و بهره برداری و ارائه خدمات پس از فروش به فعالان این صنعت می
            باشد. امروز “مشارکت نیرو” با تکیه بر تجربه ارزشمند بیش از دو دهه­ای
            مدیران خود در داخل و خارج از کشور در این صنعت، گام­های بسیار موثری
            را در پیشبرد و ارتقا کمی و کیفی سطح محصولات و ارائه خدمات، با هدف
            ایجاد بالاترین سطح رضایت مشتریان برداشته است. اخذ نمایندگی انحصاری
            POWERSHARE انگلستان، نمایندگی انحصاری MBH آلمان، نمایندگی رسمی
            MASONS ایتالیا تنها بخش کوچکی از فعالیت ها و توانمندی های این شرکت
            می باشد.
          </p>
        </section>
        <section
          id="about-us-2"
          className="grid grid-cols-2 sm:grid-cols-1 items-center  p-10 sm:px-5 bg-primary"
        >
          <div id="about-us-pic2" className="p-10 sm:px-5">
            <img src={aboutUs2} alt="در باره مشارکت نیرو" />
          </div>

          <div className="flex flex-col items-center space-y-6 p-10 sm:px-5 font-medium text-white">
            <h3 className="text-4xl ">حوزه های کاری</h3>
            <p className="text-xl">
              شامل تولید برق، صنایع، نفت، گاز و پتروشیمی میباشد.
            </p>
            <h4 className="text-3xl ">تولید برق</h4>
            <p className="text-lg font-normal">
              تامین برق در صنایع بالادستی و پایین دستی فعال در حوزه های نفت، گاز
              و پتروشیمی امری حیاتی و بسیار تخصصی است. ما در مشارکت نیرو با تکیه
              بر دانش و تجربه و همچنین تخصص تیم همراه خود آماده ارائه این خدمات
              به این صنایع در کوتاه ترین زمان ممکن می باشد. تامین برق با توجه به
              نیاز مشتری با استفاده از انواع نیروگاه های قابل تامین در این
              مجموعه مانند نیروگاه های دیزلی، نیروگاه مقیاس کوچک گازی و یا
              استفاده از انواع نیروگاه های تجدیدپذیر تنها بخشی از توانمندی
              مشارکت نیرو در این حوزه می باشد. همچنین این مجموعه آماده ارائه
              مشاوره در جهت احداث نیروگاه با استفاده از گازهای فلر و یا تامین
              برق روی سکوهای نفتی در جایجای کشور پهناور ایران می باشد.
            </p>
          </div>
        </section>
        <section
          id="about-us-2-extra"
          className="px-10 py-5 text-lg font-normal text-white bg-primary"
        >
          <h4 className="text-3xl my-5 ">صنایع</h4>
          <p className="text-lg font-normal">
            عمده کارخانجات و صنایع در کشور نسبت به تامین برق پایدار حساس بوده و
            عموما در طول سال بعلت نوسانات مختلف در شبکه سراسری برق خسارت های
            زیادی را متحمل می شوند. در همین خصوص مشارکت نیرو آماده با تکمیل
            نیروی فنی خود و جمع آوری تیم حرفه ای از کاشناسان متخصص در حوزه صنایع
            مختلف، آماده ارائه مشاوره های تخصصی در زمینه احداث نیروگاه و یا
            تامین موتورژنراتورهای دیزلی و گازسوز با توجه به نیاز هر صنعت می
            باشد. این مشاوره ها عمدتا در زمینه ممیزی انرژی در حوزه نیروگاهی بوده
            و با بررسی خطاها و یا ضعف های موجود در سیستم شبکه برق آن مجموعه نسبت
            به ارائه راهکار برای اصلاح و یا بهبود وضعیت آن صنعت اقدام می نماید.
          </p>

          <h4 className="text-3xl my-5">نفت، گاز و پتروشیمی</h4>
          <p className="text-lg font-normal">
            تامین برق در صنایع بالادستی و پایین دستی فعال در حوزه های نفت، گاز و
            پتروشیمی امری حیاتی و بسیار تخصصی است. ما در مشارکت نیرو با تکیه بر
            دانش و تجربه و همچنین تخصص تیم همراه خود آماده ارائه این خدمات به
            این صنایع در کوتاه ترین زمان ممکن می باشد. تامین برق با توجه به نیاز
            مشتری با استفاده از انواع نیروگاه های قابل تامین در این مجموعه مانند
            نیروگاه های دیزلی، نیروگاه مقیاس کوچک گازی و یا استفاده از انواع
            نیروگاه های تجدیدپذیر تنها بخشی از توانمندی مشارکت نیرو در این حوزه
            می باشد. همچنین این مجموعه آماده ارائه مشاوره در جهت احداث نیروگاه
            با استفاده از گازهای فلر و یا تامین برق روی سکوهای نفتی در جایجای
            کشور پهناور ایران می باشد.
          </p>
        </section>
      </div>
    </Layout>
  )
}
